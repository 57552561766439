import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../service/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    const logout = localStorage.getItem('logout');

    const logoutchangepw = localStorage.getItem('logout-change-pw');

    // const sessionLoggedIn = sessionStorage.getItem('logged-in');

    if (!logout) {
      if (currentUser) {
        // authorised so return true
        return true;
      }
    }

    if (logoutchangepw) {
      this.router.navigateByUrl('auth/signin');
      return false;
    }

    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    //this.router.navigate(['auth', 'signin'], { queryParams: { returnUrl: state.url } });
    this.router.navigate(['layout/trangchu']);
    return false;
  }

}