import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DattaConfig } from '../app-config';
import { SysUserGroup } from '../model/Sysusergroup';
import { SysUser } from '../model/Sysuser';

import { Cauhinhhethong } from '../model/Cauhinhhethong';
import { SysFunctionAction } from '../model/sysfunctionaction';
import { SysFunctionRole } from '../model/sysfunctionrole';



@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  public dattaConfig: any;
  token: string;
  httpOptions: any;


  constructor(private httpClient: HttpClient) {
    this.dattaConfig = DattaConfig.config;

  }

  ngOnInit(): void {

  }
  //NhomNguoiDung
  getSysUserGroup() {
    return this.httpClient.get<SysUserGroup[]>(this.dattaConfig['httplink'] + '/sysUserGroup/get');
  }
  addSysUserGroup(newsysUserGroup: SysUserGroup) {
    return this.httpClient.post<SysUserGroup>(this.dattaConfig['httplink'] + '/sysUserGroup/add', newsysUserGroup);
  }
  deleteSysUserGroup(id) {
    return this.httpClient.delete<SysUserGroup>(this.dattaConfig['httplink'] + '/sysUserGroup/' + id);
  }
  updateSysUserGroup(updatedsysUserGroup: SysUserGroup) {
    return this.httpClient.put<SysUserGroup>(this.dattaConfig['httplink'] + '/sysUserGroup/update', updatedsysUserGroup);
  }
  //End NhomNguoiDung

  //User  
  sysUsers() {
    return this.httpClient.get<SysUser[]>(this.dattaConfig['httplink'] + `/sysUser`);
  }

  getSysUser(typeuser: number) {
    return this.httpClient.get<SysUser[]>(this.dattaConfig['httplink'] + `/sysUser/findallbytypeuser/${typeuser}`);
  }

  findAllByGroup(groupid: number, typeuser: number) {
    return this.httpClient.get<SysUser[]>(this.dattaConfig['httplink'] + `/sysUser/findallbygroup/${groupid}/${typeuser}`);
  }

  addSysUser(newsysUser: SysUser, type: number) {
    return this.httpClient.post<SysUser>(this.dattaConfig['httplink'] + '/sysUser/add/' + type, newsysUser);
  }
  addSysAccount(newsysUser: SysUser) {
    return this.httpClient.post<SysUser>(this.dattaConfig['httplink'] + '/sysUser/adduser', newsysUser);
  }
  deleteSysUser(id) {
    return this.httpClient.delete<SysUser>(this.dattaConfig['httplink'] + '/sysUser/' + id);
  }
  updateSysUser(updatedsysUser: SysUser) {
    return this.httpClient.put<SysUser>(this.dattaConfig['httplink'] + '/sysUser/update', updatedsysUser);
  }
  changepwSysUser(updatedsysUser: SysUser) {
    return this.httpClient.put<SysUser>(this.dattaConfig['httplink'] + '/sysUser/changepw', updatedsysUser);
  }

  findSysUserByUserName(username: string) {
    return this.httpClient.get<SysUser>(this.dattaConfig['httplink'] + '/sysUser/get/' + username);
  }

  findSysUserByUserId(id: number) {
    return this.httpClient.get<SysUser>(this.dattaConfig['httplink'] + '/sysUser/findbyid/' + id);
  }
  //End User

  //Cau hinh he thong
  getListConfig() {
    return this.httpClient.get<Cauhinhhethong[]>(this.dattaConfig['httplink'] + '/cauhinhhethong/get');
  }
  getConfigCoHL() {
    return this.httpClient.get<Cauhinhhethong[]>(this.dattaConfig['httplink'] + '/cauhinhhethong/getcohieuluc');
  }
  addConfig(newItem: Cauhinhhethong) {
    return this.httpClient.post<Cauhinhhethong>(this.dattaConfig['httplink'] + '/cauhinhhethong/add', newItem);
  }
  deleteConfig(id) {
    return this.httpClient.delete<Cauhinhhethong>(this.dattaConfig['httplink'] + '/cauhinhhethong/' + id);
  }
  updateConfig(updatedItem: Cauhinhhethong) {
    return this.httpClient.put<Cauhinhhethong>(this.dattaConfig['httplink'] + '/cauhinhhethong/update', updatedItem);
  }

  //long
  checkPassword(username: string, pwd: string) {
    return this.httpClient.get<boolean>(this.dattaConfig['httplink'] + '/sysUser/checkpwd/' + username + '/' + pwd);
  }

  findAllByUser(userid: number) {
    return this.httpClient.get<SysUser[]>(this.dattaConfig['httplink'] + `/sysUser/findallbyuser/${userid}`);
  }

  //update 26/05/2022

  getSysFunctionRoleByCode(code: string){
    let pars = {
      params: {
        code: code
      }
    };
    return this.httpClient.get<SysFunctionRole>(this.dattaConfig['httplink'] + `/sysfunctionrole/findBycode`,pars);
  }

  getSysFunctionActionsByRoleId(id: number){
    return this.httpClient.get<SysFunctionAction[]>(this.dattaConfig['httplink'] + `/sysfunctionaction/getfunctionrolebyid/${id}`);
  }

  getAllFunctionByRole(id: number){
    return this.httpClient.get<SysFunctionAction[]>(this.dattaConfig['httplink'] + `/sysfunctionaction/getallfunctionbyrole/${id}`);
  }

  addSysFunctionAction(entity: SysFunctionAction[]) {
    return this.httpClient.post(this.dattaConfig['httplink'] + '/sysfunctionaction/add', entity);
  }


}

