<div class="navbar-brand header-logo">
  <!-- <a [routerLink]="['/dashboard/default/']" class="b-brand"> -->
    <a [routerLink]="['/']" class="b-brand">
    <div class="b-bg">
      <!--<i class="feather icon-trending-up"></i>-->
    </div>
    <span class="b-title">Cinotec</span>
  </a>
  <a href="javascript:" class="mobile-menu" [ngClass]="{'on' : navCollapsed}" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
</div>
